import Speaker1 from "../assets/images/davit-28.png";
import Speaker0 from "../assets/images/2-02 (1).png";

export const welcomeArrGeo = [
  // {
  //   id: 0,
  //   name: "ლევან დავითაშვილი",
  //   position: `პირველი ვიცე-პრემიერი <br /> საქართველოს ეკონომიკისა და მდგრადი განვითარების მინისტრი`,
  //   description: `ლევან დავითაშვილმა საქართველოს ეკონომიკისა და მდგრადი განვითარების
  //   მინისტრის თანამდებობა 2022 წლის თებერვალში დაიკავა. 2021 წლის
  //   ივლისიდან არის საქართველოს ვიცე - პრემიერი. <br />
  //   ლევან დავითაშვილი პასუხისმგებელია ქვეყნის ეკონომიკური პოლიტიკის
  //   ფორმირებასა და მიმდინარე რეფორმების წარმართვაზე, ვიცე - პრემიერის
  //   რანგში, კოორდინაციას უწევს მთავრობის ეკონომიკური გუნდის საქმიანობას.
  //   მისი პორტფოლიო მოიცავს პასუხისმგებლობების ფართო სპექტრს, მათ შორის
  //   საგარეო ვაჭრობისა და ინვესტიციების ზედამხედველობას, ადგილობრივი
  //   წარმოებისა და სერვისების ხელშეწყობას, ტურიზმის პოპულარიზაციას,
  //   სახელმწიფო საკუთრებაში არსებული აქტივების მართვას, ტრანსპორტისა და
  //   ლოჯისტიკის სექტორის ზედამხედველობას, სამშენებლო ინდუსტრიის
  //   ხელმძღვანელობას, ელექტრონული კომუნიკაციების მართვას, ინოვაციურ
  //   განვითარებას და ასევე, ეკოსისტემისა და საინფორმაციო ტექნოლოგიების,
  //   ენერგეტიკის სექტორის რეგულირებასა და სამთო ოპერაციების
  //   ზედამხედველობას. <br />
  //   ამჟამინდელ თანამდებობამდე ლევან დავითაშვილი ისეთ საკვანძო
  //   აღმასრულებელ პოზიციებს იკავებდა, როგორიც არის სოფლის მეურნეობის
  //   მინისტრი, სოფლის მეურნეობის მინისტრის მოადგილე და ღვინის ეროვნული
  //   სააგენტოს ხელმძღვანელი. <br />
  //   ლევან დავითაშვილს აქვს თბილისის სახელმწიფო უნივერსიტეტის მაგისტრის
  //   ხარისხი საერთაშორისო ბიზნეს მენეჯმენტში და მილანის პოლიტექნიკური
  //   უნივერსიტეტის მაგისტრის ხარისხი ბიზნესის ადმინისტრირებაში. მისი ფართო
  //   <br />
  //   პროფესიული გამოცდილება მოიცავს როგორც არასამთავრობო, ასევე,
  //   საერთაშორისო ორგანიზაციებსა და კერძო სექტორში მუშაობის გამოცდილებას.
  //   აღსანიშნავია, რომ მან მნიშვნელოვანი წვლილი შეიტანა ღვინის საექსპორტო
  //   ბაზრების დივერსიფიკაციასა და საქართველოდან ღვინის ექსპორტის ზრდის
  //   ხელშეწყობაში. იგი, ასევე მუშაობდა USAID-ის სოფლის მეურნეობის
  //   განვითარების პროექტზე და მსოფლიო ბანკის ბიზნეს კონსულტანტად.
  //   ლევან დავითაშვილი დაქორწინებულია და ჰყავს ორი შვილი.`,
  //   img: Speaker1,
  // },
  {
    id: 1,
    name: "ალექსანდრე ონოფრიშვილი",
    position: `დირექტორი <br /> სსიპ კურორტების განვითარების სააგენტო`,
    description: `ალექსანდრე ონოფრიშვილი 2022 წლიდან ხელმძღვანელობს საქართველოს ეკონომიკისა
    და მდგრადი განვითარების სამინისტროს სსიპ კურორტების განვითარების სააგენტოს.
    2018-2022 წლებში ალექსანდრე ონოფრიშვილი მუშაობდა საქართველოს ეკონომიკისა და
    მდგრადი განვითარების მინისტრის მრჩევლის პოზიციაზე. <br />
    2013-2018 წლებში იგი მართავდა შპს მთის კურორტების განვითარების კომპანიას,
    რომლის დაქვემდებარებაშიც 5 სამთო-სათხილამურო კურორტი - გუდაური, ბაკურიანი,
    თეთნულდი, ჰაწვალი და გოდერძი შედიოდა.<br />
    ალექსანდრე 2004-2008 წლებში იყო საქართველოს მთამსვლელთა ფედერაციის
    გამგეობის წევრი, მარკეტინგისა და საზოგადოებასთან ურთიერთობის სამსახურის
    ხელმძღვანელი, ყინულზე ცოცვის საქართველოს პირველობის ორგანიზატორი და
    გიდების გადამზადების კოორდინატორი.<br />
    იგი ასევე გახლდათ ტურისტული კომპანია ”ჯორჯიან ნატურალისტ თრეველერის”
    დამფუძნებელი და პარტნიორი, სარეკლამო სააგენტო „TBC TV სტუდიო“ პროექტების
    მენეჯერი და პროდიუსერი.<br />
    1997-2001 წლებში ალექსანდრე ონოფრიშვილი სწავლობდა თბილისის ივანე
    ჯავახიშვილის სახელობის თბილისის სახელმწიფო უნივერსიტეტის გეოგრაფია-
    გეოლოგიის ფაკულტეტზე.`,
    img: Speaker0,
  },
];
export const welcomeArrEng = [
  // {
  //   id: 0,
  //   name: "Levan davitashvili",
  //   position:
  //     "First Vice Prime Minister <br /> Minister of Economy and Sustainable Development of Georgia",
  //   description: `Levan Davitashvili assumed the role of Minister of Economy and Sustainable Development of
  //   Georgia in February 2022, concurrently serving as the Vice Prime Minister since July 2021. <br />
  //   Responsible for shaping the nation's economic policies and driving ongoing reforms, in his
  //   capacity as Vice Prime Minister, Mr. Davitashvili coordinates the activities of government's
  //   economic team. His portfolio encompasses a wide array of responsibilities, including overseeing
  //   foreign trade and investment, fostering local production and services, promoting tourism,
  //   managing state-owned assets, supervising the transport and logistics sector, guiding the
  //   construction industry, managing electronic communications, nurturing an innovative ecosystem
  //   and information technologies, regulating the energy sector, and overseeing mining operations
  //   among other crucial areas. <br />
  //   Prior to his current position, Levan Davitashvili held key executive roles such as Minister of
  //   Agriculture, Deputy Minister of Agriculture, and Head of the National Wine Agency. He brings to
  //   the table a wealth of experience and expertise in these sectors. <br />
  //   Mr. Davitashvili is an accomplished economist, holding a Master's Degrees in International
  //   Business Management from Tbilisi State University and Business Administration from the
  //   Polytechnic University of Milan. His extensive professional background spans work in the NGO
  //   sector, international organizations, and the private sector. Notably, he has made significant
  //   contributions to diversifying wine export markets and fostering the growth of wine exports
  //   from Georgia. His previous roles include working on the USAID Agricultural Development
  //   Project and serving as a business consultant for the World Bank.
  //   Mr. Davitashvili is married and has two children.`,
  //   img: Speaker1,
  // },
  {
    id: 1,
    name: "Aleksandre Onoprishvili",
    position: `Director <br /> LEPL Resorts Development Agency`,
    description: `Aleksandre Onoprishvili assumed the role of head of Resorts Development Agency of the
    Ministry of Economy and Sustainable Development of Georgia in July, 2022.
    2018-2022 He worked as an advisor to the Minister of Economy and Sustainable Development of
    Georgia. <br />
    From 2013 to 2018, Aleksandre successfully led the LLC Mountain Resorts Development
    Company, overseeing the strategic management of five premier mountain-ski resorts—Gudauri,
    Bakuriani, Tetnuldi, Hatsvali, and Goderdzi.<br />
    In 2004-2008, Aleksandre was a member of the board of the United Federation of Georgian
    Mountaineers. He held the position of head of the marketing and public relations, organizer of
    the Georgian ice climbing championship and coordinator of guide training.
    He also served as the founder and partner of the travel company "Georgian Naturalist Traveler"
    and took on roles as the project manager and producer at the advertising agency "TBC TV
    Studio".<br />
    In the years 1997-2001, Aleksandre Onoprishvili studied at the Geography-Geology Faculty of
    Ivane Javakhishvili Tbilisi State University and is a specialist in geomorphology and geoecology
    by profession.`,
    img: Speaker0,
  },
];
